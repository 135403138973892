import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AssetType } from '@shared/models';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Pipe({
  name: 'sortFormArray',
  pure: false
})
export class SortFormArrayPipe implements PipeTransform {

  currentLength = 0;
  sortedArray: any;

  constructor(
    private readonly cdRef: ChangeDetectorRef
  ) {}

  transform(formArray: AbstractControl[], components: AssetType[]): AbstractControl[] {
    if ((formArray?.length && components) && (this.currentLength !== formArray.length)) {
      this.currentLength = formArray?.length;

      const sortedFormArray = formArray?.map((control: AbstractControl) => {
        const controlName = components.find(component => component.code === control.value.component);
        return {...control, controlName: controlName.name};
      }).sort((a,b) => a.controlName.localeCompare(b.controlName));

      this.sortedArray = [...sortedFormArray];
      this.cdRef.detectChanges();
      return sortedFormArray as any;
    } else {
      return this.sortedArray?.length ? this.sortedArray : formArray;
    }

  }

}
