import { MESSAGES } from '@shared/constants';

export enum IssueStatus {
  NEW = 'New',
  IN_REPAIR = 'In Repair',
  VALIDATING = 'Validating',
  FIXED = 'Fixed',
  NO_FAULT = 'No Fault'
}

export enum SeverityStatus {
  CRITICAL = 'Critical',
  MINOR = 'Minor',
}

export type IssueAction = 'Assign' | 'No Fault' | 'For Validation' | 'Set Severity';

export const IssueActionConfirmation: Record<IssueAction, string> = {
  ['Assign']: MESSAGES.confirmAssignToMaintenance,
  ['No Fault']: MESSAGES.confirmMarkAsNoFault,
  ['For Validation']: MESSAGES.confirmSetForInspectorValidation,
  ['Set Severity']: MESSAGES.confirmSetSeverity
}

export const IssueActionSuccess: Record<IssueAction, string> = {
  ['Assign']: MESSAGES.successAssignToMaintenance,
  ['No Fault']: MESSAGES.successMarkAsNoFault,
  ['For Validation']: MESSAGES.successSetForInspectorValidation,
  ['Set Severity']: MESSAGES.successSetSeverity
}

export const NotificationFilterOptions: string[] = [
  'New',
  'Reworked',
  'Validated',
  'Validating',
  'Out of Service'
];
