import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChunkErrorHandler } from '@core/error/chunk-error-handler';
import { BlobErrorHttpInterceptor } from '@core/interceptors/blob-error.interceptor';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { HttpRequestInterceptor } from '@core/interceptors/http-request.interceptor';
import { ReactiveFormConfig, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { FIELD_ERROR } from '@shared/constants';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';

const ngZorroConfig: NzConfig = {
  message: { nzMaxStack: 1, nzDuration: 8000, nzPauseOnHover: true },
  notification: { nzMaxStack: 1, nzDuration: 5000 },
  modal: { nzMaskClosable: false, nzCloseOnNavigation: true },
  table: { nzSize: 'default', nzBordered: false },
  drawer: { nzCloseOnNavigation: true },
  datePicker: { nzSeparator: '-' },
  card: { nzBordered: false },
  image: { nzCloseOnNavigation: true },
  popconfirm: { nzAutofocus: 'ok' },
};

ReactiveFormConfig.set({
  validationMessage: FIELD_ERROR
});

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzModalModule,
    NzMessageModule,
    NzNotificationModule,
    NzSpinModule,
    RxReactiveFormsModule
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    },
    {
      provide: ErrorHandler,
      useClass: ChunkErrorHandler
    },
  ],
  declarations: []
})
export class CoreModule { }
