import { Pipe, PipeTransform } from '@angular/core';
import { environment as env } from '@env/environment';

@Pipe({
  standalone: true,
  name: 'files'
})
export class FilesPipe implements PipeTransform {

  transform(name: string): string {
    return encodeURI(`${env?.fileUrl || env?.appUrl}/api/files/shlonak/${name}`);
  }
}
