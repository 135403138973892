import { Audit } from './audit.model';

export interface Notification extends Audit {
  id: number;
  deleted: boolean;
  code: string;
  channel: string;
  event: string;
  message: string;
  seen: boolean;
  body: {
    issueType: string;
    issueCode: string;
    componentIcon: string;
    assetStatus?: string;
    assetCode?: string;
    assetImage?: string;
  }
}

export class NotificationFilterParams {
  issueType?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  assetStatus?: string;
}
