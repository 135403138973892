import { Pipe, PipeTransform } from '@angular/core';
import { AssetComponent } from '@shared/models';
import { ComponentsService } from '@shared/services/api';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getComponent',
})
export class GetComponentPipe implements PipeTransform {

  constructor(
    private readonly cs: ComponentsService
  ) {}

  transform(code: string): Observable<AssetComponent> {
    return this.cs.getComponent(code);
  }

}
