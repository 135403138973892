import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

@Pipe({
  name: 'nestedArrayPipe'
})
export class NestedArrayPipe implements PipeTransform {

  transform(group: AbstractControl, controlName?: string): AbstractControl[] {
    return (group.get(controlName) as FormArray)?.controls;
  }

}
