import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'getFormArrayIndex',
  pure: true
})
export class GetFormArrayIndexPipe implements PipeTransform {

  transform(current: AbstractControl, formArray: AbstractControl[]): number {
    if (current) {
      const index = formArray?.findIndex(control => control.value.component === current.value.component);
      return index;
    }

    return 0;
  }

}
