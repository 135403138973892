import { Pipe, PipeTransform } from '@angular/core';
import { UserGroupLabel } from '@shared/constants';

@Pipe({
  name: 'userGroup'
})
export class UserGroupPipe implements PipeTransform {

  transform(groups: string[] | string): string {
    if (groups instanceof Array) {
      return groups?.map(group => UserGroupLabel[group]).join(', ') || '-';
    } else {
      return UserGroupLabel[groups];
    }
  }

}
