import { Pipe, PipeTransform } from '@angular/core';
import { AssetType } from '@shared/models';

@Pipe({
  name: 'componentName'
})
export class GetComponentNamePipe implements PipeTransform {

  transform(code: string, componentList: AssetType[]): string {
    return (componentList?.find(comp => comp?.code === code)?.name) || '-';
  }

}
