import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropdownMsg'
})
export class DropdownLoadingMessagePipe implements PipeTransform {

  transform(itemName: string, loading: boolean): string {
    return loading ? `Loading ${itemName}s...` : `Select ${itemName}`;
  }

}
