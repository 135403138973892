import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DATE_FORMAT } from '@shared/constants';

@Pipe({
  standalone: true,
  name: 'date'
})
export class ExtendedDatePipe extends DatePipe implements PipeTransform {
  readonly customFormats = {
    short: DATE_FORMAT.DATETIME_12_HOUR,
    shortDate: DATE_FORMAT.DEFAULT,
    shortTime: DATE_FORMAT.TIME_12_HOUR,
    month: DATE_FORMAT.YEAR_MONTH
  };

  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  transform(value: string | number | Date, format?: string | undefined,
            timezone?: string | undefined, locale?: string | undefined): string | null {

    if (format) {
      if (Object.keys(this.customFormats).find(f => f === format)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        format = this.customFormats[format];
      }
    } else {
      format = this.customFormats.short;
    }

    return super.transform(value, format, timezone, locale);
  }
}
