import { Pipe, PipeTransform } from '@angular/core';
import { isWithinInterval, subHours } from 'date-fns';

@Pipe({
  name: 'within24Hours'
})
export class Within24HoursPipe implements PipeTransform {

  transform(value: Date | string | number): boolean {
    if (value) {
      const now = new Date();
      const twentyFourHoursAgo = subHours(now, 24);

      // Convert value to a UTC Date
      const inputDate = new Date(value);
      return isWithinInterval(inputDate, { start: twentyFourHoursAgo, end: now });
    }
    return false;
  }

}
