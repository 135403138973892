import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'isSvg'
})

export class CheckSvgPipe implements PipeTransform {

  transform(name: string): boolean {
    if (name) {
      const split = name.split('.');
      return split[split?.length - 1] === 'svg';
    }
    return false;
  }
}
